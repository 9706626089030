import gif1 from "../Assets/1.gif";
import image2 from "../Assets/2.jpg";
import gif3 from "../Assets/3.gif";
import image4 from "../Assets/4.jpg";
import image5 from "../Assets/5.jpg";
import image6 from "../Assets/6.jpg";
import image7 from "../Assets/7.jpg";
import image8 from "../Assets/8.jpg";
import image9 from "../Assets/9.jpg";
import gif10 from "../Assets/10.gif";


const imagesData = [
  gif1,
  image2,
  gif3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  gif10
];

export default imagesData;
